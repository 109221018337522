
.body {
  width: 100%;
  height: 100%;
  align-self: flex-end;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .up,
  .down {
    height: 45%;
    width: 100%;
  }

  .up {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

  }

  .down {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    .info {
      width: 33%;
      max-width: 31.313rem;

      display: flex;
      justify-content: stretch;
      align-items: stretch;

      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        li {
          width: 100%;
          margin-bottom: 1rem;

          display: flex;
          justify-content: space-between;
          align-items: center;


        }
      }
    }
    .title-container {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      text-align: center;
      max-width: 25.125rem;
      * {
        color: $dark-mustard;
      }
      .down-text {
        margin-top: 10%;
      }
    }
  }

}


.contact {
  position: fixed;
  bottom: 100px;
  right: 20px;

  background: $dark-mustard;
  border-radius: 5.531rem 0 5.531rem 0;
  width: 20.5rem;
  height: 11.063rem;
  padding: 1rem;
  
  display: flex;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  .message,
  .telephone {
    position: absolute;
  }

  .message {
    left: -2.5rem;
    top: -.05rem;
  }

  .telephone {
    right: 0.4rem;
    top: 1rem;
  }
}

