@import "./vars.scss";

.plans {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;

  padding: 1rem;
  width: 100%;

  flex-wrap: wrap;
  .plan {
    background: $dark-mustard;
    max-width: 500px;

    .header {
      background: $hover;
    }

    .plan-body {
      padding:1rem 2rem;

      .row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .icon {
          width: 32px;
          margin-right: 10px;

          path {
            fill: $banana;
          }
        }

        p, a {
          font-weight: bold;
        }
        
      }
    }

    .plan-footer {
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .contact-text {
        position: relative;
        margin-bottom: .5rem;
        p {
          font-weight: bold;
        }
        .icon {
          position: absolute;
          left: -30px;
          top: -17px;
  
          width: 32px;
          path {
            fill: $banana;
          }
        }
      }


      

    }

  }
}