$dark-mustard: #B78700;
$mustard: #D39E00;
$yellow: #EEB601;
$light-yelow: #F8DA44;
$banana: #FFFF71;
$hover: #FFC700;
$white: #FFFFFF;
$black: #000000;


.title {
  font-size: 4.5rem;
  font-weight: bold;

  &-regular {
    font-size: 2rem;
    font-weight: 400;
  }
  &-extra-bold {
    font-size: 3rem;
    font-weight: 800;
  }
}

.small {
  font-size: 1rem;
}

.paragraph {
  font-size: 2.25rem;
  font-weight: 700;
  color: $dark-mustard;
}

.no-select {
  user-select: none;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}