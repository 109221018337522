@import '../scss/vars.scss';

.nav-bar {
  display: flex;

  margin-left: auto;

  &__text {
    margin-top: 0.5rem;
  }

  &__icon {
    width: 60px;
    
    path {
      fill: $dark-mustard;
    }
  }

  .nav-bar__item {
    margin-left: 1.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: $white;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    cursor: pointer;
    margin-right: 4rem;
  
    &:hover {
      .nav-bar__text{
        color: $white;
      }
  
      .nav-bar__icon path {
        fill: $white;
      }
    }
  }
  
  .nav-bar__item--active {
    position: relative;

    .nav-bar__text{
      color: $white;
    }
  
    .nav-bar__icon path {
      fill: $white;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 150%;
      height: 200%;
      background-color: $dark-mustard;
      z-index: -1;

      border-radius: 0 0 1rem 1rem ;
    }
  }

}
