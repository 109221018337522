@import "./vars.scss";

.payment-methods {
  display: flex;

  align-items: stretch;
  justify-content: space-around;

  width: 100%;
  height: 100%;
  margin-top: 5rem;
  .column {
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    gap: 2rem;

    .icon {
      height: 85px;
    }

    p {
      font-size: 2rem;
      color: $black;
    }

    b {
      color: black;
    }
  }
}