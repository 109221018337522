@import './vars.scss';

body {
  background-color: $banana;
  position: relative;
}

.mobile-main {
  padding: 0;
  .header {
    flex-direction: column;
    margin-bottom: 1.25rem;
    .title-slogan{
      p {
        color: $dark-mustard;
      }
      .slogan {
        font-weight: 400;
        letter-spacing: .3rem;
      }
      .title {
        font-size: 3rem;
        margin-bottom: .5rem;
      }

    } 



    .logo {
      margin: 0;
    }
  }

  .mobile-body {
    color: $dark-mustard;
    .down {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: unset;
      overflow: auto;

      .title-container{
        *{
          font-size: 1.5rem;
        }
        margin-bottom: 2.125rem;

        justify-content: flex-start;
        align-items: center;
        align-self: center;

        .down-text p a{
          font-size: 1.125rem;
        }
      }

      .info {
        width: 60%;
        max-width: 17rem;
        .paragraph {
          font-size: 1.125rem;
        }
        .icon {
          width: 1.5rem;
        }
      }

      .contact {
        height: 8.188rem;
        width: 14.449rem;
        border-radius: 1.25rem 0 1.25rem 0;

        justify-content: space-between;
        align-items: center;

        .title-regular {
          font-size: 1.5rem;
        }
        .message {
          top: -0.05rem;
          left: -2.5rem;
          width: 5rem;
        }
        .telephone {
          right: -0.6rem;
          top: -1rem;
          width: 4rem;
        }
      }
    }
  }
}


.menu { 
  position: sticky;
  bottom: 0;
  background: $dark-mustard;
  width: 100%;
  height:5rem;

  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 0.938rem 1.25rem;
  .hover-alert {
    &.computer {
      .bubble-message {
        &::before {
          left: 0.8rem;
        }
      }
    }
    &.music {
      .bubble-message {
        &::before {
          left: 8rem;
        }
      }
    }
    .bubble-message {
      transform: unset;
    }
  }
}