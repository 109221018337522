@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');
@import './vars.scss';
@import './body.scss';
@import './bubble.scss';
@import './mobile.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
  color: white;
}

body {
  background-color: $banana;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section {
    width: 100%;
    height: 50%;
    &-1 {
      background: $dark-mustard;
    }
    &-2 {
      background: $banana;
    }
  }

  .spacer {
    aspect-ratio: 9/16;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .layer {
    background-image: url('../icons/Background.svg');
  }

}

.main {
  width: 100%;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  

  .header {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    
    .title-slogan {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .slogan {
        letter-spacing: 0.5rem;
        font-weight: 900;
      }
    }
    .logo {
      margin-right: 1.938rem;
    }

    p {
      color: $dark-mustard;
    }
    .slogan {
      font-weight: 400;
      letter-spacing: .3rem;
    }
    .title {
      font-size: 3rem;
      margin-bottom: .5rem;
    }
  }

}
