
.hover-alert {
  position: relative;
  cursor: pointer;
  svg path {
    transition: .25s all ease-in-out;
  }

  &.hovering {
    svg {
      path {
        fill: $hover;
      }
    }
  }
  .bubble-message {
    position: absolute;
    background: $mustard;
    border-radius: 0.75rem;
    padding: .5rem;
  
    transform: rotate(-4.48deg);
    width: 11.688rem;
    text-align: center;
    
    top: -6rem;
    left: -5rem;
    transition: .25s all ease-in-out;
    z-index: -2;
    opacity: 0;
    
    &.show {
      z-index: 1000;
      opacity: 1;
    }


    &.hide {
      top: -10rem !important;
      pointer-events: none;
    }

    &.right {
      &::before {
        transform: rotate(30deg);
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      bottom:  -2rem;
      left: 4rem;
      clip-path: path('M30.6148 41.3583C29.9026 43.6245 26.9642 44.2033 25.4458 42.3765L0.967132 12.9268C-0.488178 11.1759 0.460635 8.50569 2.69442 8.06569L38.6547 0.98237C40.8885 0.542368 42.7791 2.65331 42.0965 4.82528L30.6148 41.3583Z');
      background: $mustard;
      width: 3.374rem;
      height: 3.574rem;
      z-index: -1;
    }
  }
}