@import "../../scss/vars.scss";

$yellow: #EEB601;
$banana: #FFFF71;

.main {
  justify-content: flex-start;
}
.main-ift-section {
  padding: 2rem;
  border-radius: 3rem;

  width: 90%;
  margin: 1rem auto;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    h1 {
      color: $mustard;
    }
  }
  .file {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    
    a, button {
      font-size: 1.5rem;
      font-weight: 700;
      text-decoration: none;
      color: $mustard;
      font-weight: 900;

      background: unset;
      border: 0;
      cursor: pointer;

      outline: none;

      &:hover {
        text-decoration: underline;
      }
    }
    .banana {
      margin-right: 1rem;
      path {
        fill: $hover;
      }
    }
  }
}